import React, { useState, useRef } from "react";
import { Link } from "gatsby";

import Logo from "../assets/svg/logo-light.svg";
import ArrowDownIcon from "../assets/svg/arrow-down.svg";
import AcquisitionIcon from "../assets/svg/acquisition.svg";
import ConversionIcon from "../assets/svg/conversion.svg";
import RetentionIcon from "../assets/svg/retention.svg";
import OperationsIcon from "../assets/svg/operations.svg";
import FreeResourcesIcon from "../assets/svg/free-resources.svg";
import BlogArticlesIcon from "../assets/svg/blog-articles.svg";
import GrowthScorecardIcon from "../assets/svg/growth-scorecard.svg";
import GrowthStrategyIcon from "../assets/svg/growth-strategy.svg";

import useRouteChange from "../hooks/use-route-change";

import * as styles from "./Header.module.scss";

import Button from "./Button";

const links = [
  {
    href: "/services",
    label: "Services",
    children: [
      {
        icon: AcquisitionIcon,
        href: "/services/acquisition",
        label: "Acquisition",
        subtext:
          "Acquire more traffic by optimizing your organic, paid and owned channels.",
      },
      {
        icon: ConversionIcon,
        href: "/services/conversion",
        label: "Conversion",
        subtext:
          "Convert more visitors by optimizing your website, content and message.",
      },
      {
        icon: RetentionIcon,
        href: "/services/retention",
        label: "Retention",
        subtext:
          "Retain more customers by optimizing your offers, bundles and upsells.",
      },
      {
        icon: OperationsIcon,
        href: "/services/operations",
        label: "Operations",
        subtext:
          "Operate more efficiently by optimizing your logistics, strategy and spend.",
      },
    ],
  },
  {
    href: "/resources",
    label: "Resources",
    children: [
      {
        href: "/resources",
        label: "Free Resources",
        icon: FreeResourcesIcon,
        subtext:
          "Playbooks, swipe files, tools and free templates to accelerate your brand.",
      },
      {
        href: "/articles",
        label: "Blog Articles",
        icon: BlogArticlesIcon,
        subtext:
          "Learn how to utilize our methods for your brand across marketing channels.",
      },
      {
        href: "/resources/growth-scorecard",
        label: "Growth Scorecard",
        icon: GrowthScorecardIcon,
        subtext:
          "Short quiz providing insights to accelerate your brands growth.",
      },
      {
        href: "/resources/growth-strategy",
        label: "Growth Strategy",
        icon: GrowthStrategyIcon,
        subtext:
          "Custom roadmap to acquire, convert and retain 20% more customers.",
      },
    ],
  },
  { href: "/partners", label: "Case Studies" },
  { href: "/contact-us", label: "Contact Us" },
];

const Header: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [dropdowns, setDropdowns] = useState([]);
  useRouteChange(() => {
    setOpen(false);
    setDropdowns([]);
  });

  return (
    <div id="header" className={styles.container}>
      <div className={styles.wrapper}>
        <Link to="/" className={styles.logoLink} aria-label="Go to home page">
          <Logo className={styles.logo} aria-label="Growth Labs company logo" />
        </Link>
        <nav
          style={{
            "--visibility": open ? "visible" : "hidden",
            "--opacity": open ? "1" : "0",
          }}
          className={styles.nav}
        >
          <div className={styles.linkContainer}>
            {links.map((link) =>
              link.children ? (
                <React.Fragment key={link.label}>
                  <div
                    key={link.label}
                    className={styles.dropdownWrapper}
                    onMouseEnter={() => {
                      if (window.innerWidth >= 1024) {
                        setDropdowns([link.href]);
                      }
                    }}
                    onMouseLeave={() => {
                      if (window.innerWidth >= 1024) {
                        setDropdowns([]);
                      }
                    }}
                  >
                    <button
                      aria-label={""}
                      className={styles.linkWrapper}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        if (event.target === event.currentTarget) {
                          if (dropdowns.includes(link.href)) {
                            setDropdowns(
                              dropdowns.filter((item) => item !== link.href),
                            );
                          } else {
                            setDropdowns([...dropdowns, link.href]);
                          }
                        }
                      }}
                    >
                      <Link to={link.href} className={styles.link}>
                        {link.label}
                      </Link>
                      <ArrowDownIcon
                        data-open={dropdowns.includes(link.href)}
                        className={styles.arrowIcon}
                      />
                    </button>
                    <Dropdown
                      open={dropdowns.includes(link.href)}
                      links={link.children}
                    />
                  </div>
                  <hr className={styles.divider} />
                </React.Fragment>
              ) : (
                <React.Fragment key={link.label}>
                  <div className={styles.linkWrapper}>
                    <Link
                      key={link.label}
                      to={link.href}
                      className={styles.link}
                    >
                      {link.label}
                    </Link>
                  </div>
                  <hr className={styles.divider} />
                </React.Fragment>
              ),
            )}
          </div>
          <div className={styles.ctaButtonWrapper}>
            <Button
              to="/resources/growth-strategy/"
              className={styles.ctaButton}
            >
              Request Growth Strategy
            </Button>
          </div>
        </nav>
        <MenuIcon open={open} setOpen={setOpen} />
      </div>
    </div>
  );
};

const Dropdown: React.FC<{
  open: boolean;
  links: Array<{
    href: string;
    label: string;
    subtext: string;
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
  }>;
}> = ({ open, links }) => {
  const dropdown = useRef(null);

  return (
    <div
      data-open={open}
      style={{
        "--height": `${dropdown?.current?.scrollHeight || 0}px`,
      }}
      className={styles.dropdownContainer}
    >
      <div ref={dropdown} className={styles.dropdown}>
        {links.map((link) => (
          <Link key={link.label} to={link.href} className={styles.dropdownLink}>
            <div className={styles.dropdownLinkMeta}>
              <link.icon className={styles.dropdownLinkIcon} />
              <h5 className={styles.dropdownLinkTitle}>{link.label} »</h5>
            </div>
            <p className={styles.dropdownLinkSubtext}>{link.subtext}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

const MenuIcon: React.FC<{ open: boolean; setOpen: (value) => void }> = ({
  open,
  setOpen,
}) => (
  <button
    className={styles.menuIcon}
    onClick={() => setOpen(!open)}
    aria-label={`${open ? "Close" : "Open"} Menu`}
  >
    <svg className={styles.menuIconSvg} viewBox="0 0 100 100">
      <rect
        data-open={open}
        className={styles.menuIconRect1}
        x="10"
        y="20"
        width="80"
        height="10"
        ry="5"
      />
      <rect
        data-open={open}
        className={styles.menuIconRect2}
        x="10"
        y="45"
        width="80"
        height="10"
        ry="5"
      />
      <rect
        data-open={open}
        className={styles.menuIconRect3}
        x="10"
        y="70"
        width="80"
        height="10"
        ry="5"
      />
    </svg>
  </button>
);

export default Header;
