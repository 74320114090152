import React from "react";

import * as styles from "./Layout.module.scss";

import { QueryParamsProvider } from "../hooks/use-query-params";
import { NotificationProvider } from "../contexts/NotificationContext";

import Header from "./Header";
import Footer from "./Footer";
import Notifications from "./Notifications";

const Layout: React.FC<{ element: React.ReactNode }> = ({ element }) => (
  <QueryParamsProvider>
    <NotificationProvider>
      <Notifications />
      <Header />
      <main className={styles.main}>{element}</main>
      <Footer />
    </NotificationProvider>
  </QueryParamsProvider>
);

export default Layout;
