import React from "react";
import { Link } from "gatsby";

import Logo from "../assets/svg/logo-dark.svg";

import * as styles from "./Footer.module.scss";

import Button from "./Button";

const categories = [
  {
    label: "Navigate",
    links: [
      { label: "Services", href: "/services" },
      { label: "Resources", href: "/resources" },
      { label: "Case Studies", href: "/partners" },
    ],
  },
  {
    label: "Resources",
    links: [
      { label: "Free Resources", href: "/resources" },
      { label: "Blog Articles", href: "/articles" },
      { label: "Growth Scorecard", href: "/resources/growth-scorecard" },
      { label: "Growth Strategy", href: "/resources/growth-strategy" },
    ],
  },
  {
    label: "Services",
    links: [
      { label: "Acquisition", href: "/services/acquisition" },
      { label: "Conversion", href: "/services/conversion" },
      { label: "Retention", href: "/services/retention" },
      { label: "Operations", href: "/services/operations" },
    ],
  },
  {
    label: "Get In Touch",
    links: [
      { label: "Contact Us", href: "/contact-us" },
      { label: "Email Us", href: "mailto: hello@growthlabscommerce.com" },
      { label: "Call Us", href: "tel: +1-780-618-6340" },
      {
        label: "LinkedIn",
        href: "https://www.linkedin.com/company/growthlabscommerce",
      },
    ],
  },
];

const Footer: React.FC = () => (
  <div id="footer">
    <div className={styles.cta}>
      <h2 className={styles.title}>
        Ready to <i>accelerate</i> your <i>growth?</i>
      </h2>
      <Button to="/resources/growth-scorecard" className={styles.button}>
        Get your Growth Scorecard
      </Button>
      <p className={styles.buttonSubtext}>
        Seriously. It’s free and it’ll make you more
        <br />
        money. That’s a pretty decent ROI.
      </p>
    </div>
    <footer className={styles.footer}>
      <div className={styles.wrapper}>
        <div className={styles.metaWrapper}>
          <Link className={styles.logoLink} to="/">
            <Logo className={styles.logo} />
          </Link>
          <p className={styles.text}>
            We help 7/8-figure DTC brands increase profits through data-driven
            research and experimentation across acquisition, conversion and
            retention channels.
          </p>
        </div>
        <div className={styles.categories}>
          {categories.map((category, i) => (
            <div key={category.label} data-last={i === categories.length - 1}>
              <h4 className={styles.categoryTitle}>{category.label}</h4>
              {category.links.map((link) =>
                link.href.startsWith("/") ? (
                  <Link className={styles.link} key={link.label} to={link.href}>
                    {link.label}
                  </Link>
                ) : (
                  <a className={styles.link} key={link.label} href={link.href}>
                    {link.label}
                  </a>
                ),
              )}
            </div>
          ))}
        </div>
      </div>
    </footer>
  </div>
);

export default Footer;
