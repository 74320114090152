import { useEffect, useRef } from "react";
import { useLocation } from "@reach/router";

const useRouteChange = (callback: () => void): void => {
  const location = useLocation();
  const savedCallback = useRef<() => void>(null);
  const previousLocation = useRef<Location>(null);

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Trigger the callback on route change
  useEffect(() => {
    if (previousLocation.current) {
      savedCallback.current();
    }
    previousLocation.current = location;
  }, [location]);
};

export default useRouteChange;
