import React, { useContext } from "react";

import CloseIcon from "../assets/svg/close.svg";

import * as styles from "./Notifications.module.scss";

import NotificationContext from "../contexts/NotificationContext";

import InfoIcon from "../assets/svg/info.svg";
import ErrorIcon from "../assets/svg/error.svg";
import WarningIcon from "../assets/svg/warning.svg";
import SuccessIcon from "../assets/svg/success.svg";

const icons = {
  info: InfoIcon,
  error: ErrorIcon,
  warning: WarningIcon,
  success: SuccessIcon,
};

const Notifications: React.FC = () => {
  const { toasts, banners, removeNotification } =
    useContext(NotificationContext);

  const displayNotification = (notification) => {
    const Icon = icons[notification.level];

    return (
      <div
        key={notification.id}
        data-level={notification.level}
        onClick={notification.onClick}
        className={styles.notification}
      >
        <Icon data-level={notification.level} className={styles.icon}>
          {notification.content}
        </Icon>
        <p className={styles.content}>{notification.content}</p>
        <button
          onClick={() => removeNotification(notification.id)}
          className={styles.button}
        >
          <CloseIcon className={styles.closeIcon} />
        </button>
      </div>
    );
  };

  return (
    <>
      <div className={styles.toasts}>{toasts.map(displayNotification)}</div>
      <div className={styles.banners}>{banners.map(displayNotification)}</div>
    </>
  );
};

export default Notifications;
