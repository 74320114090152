exports.components = {
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-growth-scorecard-quiz-tsx": () => import("./../../../src/pages/growth-scorecard/quiz.tsx" /* webpackChunkName: "component---src-pages-growth-scorecard-quiz-tsx" */),
  "component---src-pages-growth-scorecard-results-tsx": () => import("./../../../src/pages/growth-scorecard/results.tsx" /* webpackChunkName: "component---src-pages-growth-scorecard-results-tsx" */),
  "component---src-pages-growth-strategy-quiz-tsx": () => import("./../../../src/pages/growth-strategy/quiz.tsx" /* webpackChunkName: "component---src-pages-growth-strategy-quiz-tsx" */),
  "component---src-pages-growth-strategy-thank-you-tsx": () => import("./../../../src/pages/growth-strategy/thank-you.tsx" /* webpackChunkName: "component---src-pages-growth-strategy-thank-you-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-services-acquisition-tsx": () => import("./../../../src/pages/services/acquisition.tsx" /* webpackChunkName: "component---src-pages-services-acquisition-tsx" */),
  "component---src-pages-services-conversion-tsx": () => import("./../../../src/pages/services/conversion.tsx" /* webpackChunkName: "component---src-pages-services-conversion-tsx" */),
  "component---src-pages-services-operations-tsx": () => import("./../../../src/pages/services/operations.tsx" /* webpackChunkName: "component---src-pages-services-operations-tsx" */),
  "component---src-pages-services-retention-tsx": () => import("./../../../src/pages/services/retention.tsx" /* webpackChunkName: "component---src-pages-services-retention-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-templates-article-category-tsx": () => import("./../../../src/templates/article-category.tsx" /* webpackChunkName: "component---src-templates-article-category-tsx" */),
  "component---src-templates-article-tsx-content-file-path-src-content-articles-conversion-rates-6-tips-to-improve-them-mdx": () => import("./../../../src/templates/article.tsx?__contentFilePath=/opt/build/repo/src/content/articles/conversion-rates-6-tips-to-improve-them.mdx" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-src-content-articles-conversion-rates-6-tips-to-improve-them-mdx" */),
  "component---src-templates-partner-tsx-content-file-path-src-content-partners-olsa-tools-mdx": () => import("./../../../src/templates/partner.tsx?__contentFilePath=/opt/build/repo/src/content/partners/olsa-tools.mdx" /* webpackChunkName: "component---src-templates-partner-tsx-content-file-path-src-content-partners-olsa-tools-mdx" */),
  "component---src-templates-resource-category-tsx": () => import("./../../../src/templates/resource-category.tsx" /* webpackChunkName: "component---src-templates-resource-category-tsx" */),
  "component---src-templates-resource-tsx-content-file-path-src-content-resources-growth-scorecard-mdx": () => import("./../../../src/templates/resource.tsx?__contentFilePath=/opt/build/repo/src/content/resources/growth-scorecard.mdx" /* webpackChunkName: "component---src-templates-resource-tsx-content-file-path-src-content-resources-growth-scorecard-mdx" */),
  "component---src-templates-resource-tsx-content-file-path-src-content-resources-growth-strategy-mdx": () => import("./../../../src/templates/resource.tsx?__contentFilePath=/opt/build/repo/src/content/resources/growth-strategy.mdx" /* webpackChunkName: "component---src-templates-resource-tsx-content-file-path-src-content-resources-growth-strategy-mdx" */)
}

