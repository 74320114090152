import React, { createContext, useContext, useState, useEffect } from "react";
import {
  encodeQueryParams,
  decodeQueryParams,
  QueryParamConfigMap,
  searchStringToObject,
  objectToSearchString,
  DecodedValueMap,
  EncodedQuery,
  EncodedValueMap,
} from "serialize-query-params";
import { useLocation, globalHistory } from "@reach/router";

const QueryParamsContext = createContext<
  [EncodedQuery, React.Dispatch<React.SetStateAction<EncodedQuery>>]
>([{}, () => undefined]);

export const QueryParamsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const location = useLocation();
  const [encodedParams, setEncodedParams] = useState(
    searchStringToObject(
      /**
       * Window is undefined on first render and on page transitions after first load parameters
       * are only available on the window, not the reach-router location
       */
      typeof window !== "undefined" ? window.location.search : location.search,
    ),
  );

  useEffect(() => {
    const unsubscribe = globalHistory.listen(() => {
      setEncodedParams(searchStringToObject(window.location.search));
    });

    return () => {
      unsubscribe();
    };
  });

  /**
   * The pop state event occurs when history.back() and history.forward() are used.
   * Because they affect the history directly and do not cuase a page reload in the
   * case that the item they are navigating to used history.pushState() or history.
   * replaceState(), we need to use the event listeners and update our internal state
   * accordingly.
   */
  useEffect(() => {
    const onPopState = () => {
      setEncodedParams(searchStringToObject(window.location.search));
    };

    window.addEventListener("popstate", onPopState);

    return () => {
      window.removeEventListener("popstate", onPopState);
    };
  }, []);

  return (
    <QueryParamsContext.Provider value={[encodedParams, setEncodedParams]}>
      {children}
    </QueryParamsContext.Provider>
  );
};

const useQueryParams = <QPCMap extends QueryParamConfigMap>(
  paramConfigMap: QPCMap,
): [
  DecodedValueMap<QPCMap>,
  (
    /**
     * An object containing the new parameters. Updates are done in-place, meaning that
     * parameters not defined will keep their existing values, including params not
     * defined in the config map.
     */
    newParams: Partial<DecodedValueMap<QPCMap>>,
    /**
     * Push (default) adds a new item to the history stack while replace removes the
     * current item and replaces it with the new item
     */
    mode?: "push" | "replace",
  ) => void,
] => {
  const [encodedParams, setEncodedParams] = useContext(QueryParamsContext);

  const params = decodeQueryParams(
    paramConfigMap,
    encodedParams as EncodedValueMap<QPCMap>,
  ) as DecodedValueMap<QPCMap>;

  return [
    params,
    (newParams, mode) => {
      const mergedParams = {
        ...params,
        ...newParams,
      };

      for (const [key, value] of Object.entries(mergedParams)) {
        if (
          paramConfigMap[key]?.default !== undefined &&
          paramConfigMap[key].default === value
        ) {
          delete mergedParams[key];
        }
      }

      const newEncodedParams = encodeQueryParams(paramConfigMap, mergedParams);

      const encodedSearchString = objectToSearchString(newEncodedParams);

      const search = encodedSearchString.length
        ? `?${encodedSearchString}`
        : "";

      window.history[mode === "push" ? "pushState" : "replaceState"](
        "",
        "",
        `${location.pathname}${search}${location.hash}`,
      );

      setEncodedParams(newEncodedParams);
    },
  ];
};

export default useQueryParams;
