import React from "react";
import { Link } from "gatsby";
import cx from "classnames";

import PointerIcon from "../assets/svg/pointer-curved.svg";

import * as styles from "./Button.module.scss";

type LinkProps = React.ComponentProps<typeof Link>;

type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<
  {
    flash?: boolean;
    color?: "forestGreen" | "fadedGreen";
    family?: "filled" | "outline";
    size?: "sm" | "md" | "lg";
    subtext?: React.ReactNode;
    loading?: boolean;
    disabled?: boolean;
    className?: string;
  } & (LinkProps | AnchorProps | ButtonProps)
> = ({
  flash = undefined,
  color = "forestGreen",
  family = "filled",
  size = "md",
  subtext,
  loading = false,
  disabled = false,
  className,
  ...props
}) => {
  let type;

  if ("to" in props) type = Link;
  else if ("href" in props) type = "a";
  else type = "button";

  const children = [
    <span key="children" className={styles.children}>
      {props.children}
    </span>,
    <span key="loading" className={styles.loading}>
      Loading
      <span />
      <span />
      <span />
    </span>,
  ];

  if (subtext) {
    children.push(
      <div className={styles.subtextContainer}>
        <span className={styles.subtext}>{subtext}</span>
        <PointerIcon className={styles.pointer} />
      </div>,
    );
  }

  return React.createElement(
    type,
    {
      className: cx(styles[`${size}_${color}_${family}`], className),
      ["data-flash"]: flash !== undefined ? flash : family === "filled",
      ["data-loading"]: loading,
      ["data-disabled"]: disabled,
      ...props,
    },
    children,
  );
};

export default Button;
