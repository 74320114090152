// extracted by mini-css-extract-plugin
export var button = "Footer-module--button--5bd76";
export var buttonSubtext = "Footer-module--button-subtext--ccff4";
export var categories = "Footer-module--categories--5d8ca";
export var categoryTitle = "Footer-module--category-title--d0ae0";
export var cta = "Footer-module--cta--18b47";
export var footer = "Footer-module--footer--acb34";
export var link = "Footer-module--link--ae6d7";
export var logo = "Footer-module--logo--59237";
export var logoLink = "Footer-module--logo-link--aa377";
export var metaWrapper = "Footer-module--meta-wrapper--77e2e";
export var text = "Footer-module--text--a253b";
export var title = "Footer-module--title--15a22";
export var wrapper = "Footer-module--wrapper--4664e";