import React, { createContext, useState, useRef, useEffect } from "react";

const NotificationContext = createContext<{
  toasts: Notification[];
  banners: Notification[];
  addNotification: (notification: NotificationInput, inputId?: string) => void;
  getNotification: (id: string) => Notification | undefined;
  updateNotification: (id: string, patch: Partial<NotificationInput>) => void;
  removeNotification: (id: string) => void;
}>({
  toasts: [],
  banners: [],
  addNotification: () => undefined,
  getNotification: () => undefined,
  updateNotification: () => undefined,
  removeNotification: () => undefined,
});

function generateNotificationId() {
  return Math.random().toString(36).substring(2, 9);
}

function getNotificationsByType(
  queue: Record<string, Notification>,
  type: string,
) {
  return Object.values(queue)
    .filter((value) => value !== null && value.type === type)
    .slice(0, 3)
    .reverse();
}

type NotificationInput = {
  type: "toast" | "banner";
  level: "info" | "error" | "success";
  content: string;
  timeout?: number;
};

type Notification = {
  id: string;
  type: "toast" | "banner";
  level: "info" | "error" | "success";
  content: string;
  timeout?: number;
};

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const timers = useRef<Record<string, NodeJS.Timeout>>({});
  const [queue, setQueue] = useState<Record<string, Notification>>({});

  const toasts = getNotificationsByType(queue, "toast");
  const banners = getNotificationsByType(queue, "banner");

  const addNotification = (notification: Notification, inputId?: string) => {
    const id = inputId || generateNotificationId();
    setQueue((state) => ({ ...state, [id]: { id, ...notification } }));
  };

  const getNotification = (id: string) => queue[id];

  const updateNotification = (id: string, patch: Partial<Notification>) => {
    if (queue[id]) {
      setQueue((state) => ({ ...state, [id]: { ...state[id], ...patch } }));
    }
  };

  const removeNotification = (id: string) => {
    if (timers.current[id]) {
      clearTimeout(timers.current[id]);
      timers.current[id] = null;
    }

    setQueue((state) => ({ ...state, [id]: null }));
  };

  for (const { id, timeout } of [...toasts, ...banners]) {
    if (timeout && !timers.current[id]) {
      timers.current[id] = setTimeout(() => {
        removeNotification(id);
      }, timeout);
    }
  }

  useEffect(
    () => () => {
      for (const timer of Object.values(timers.current)) {
        if (timer !== null) {
          clearTimeout(timer);
        }
      }
    },
    [],
  );

  return (
    <NotificationContext.Provider
      value={{
        toasts,
        banners,
        addNotification,
        getNotification,
        updateNotification,
        removeNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;
